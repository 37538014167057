import { AssetCareError, ErrorOrigin } from '@/@types';
import { filterSidebarId } from '@/constants';
import { useDashboardDataContext } from '@/hooks';
import { DashboardHeader, DashboardTable, StatusCardList } from './components';

export const Dashboard = () => {
  const { data, dashDataError, fetchStatus, signalRError } =
    useDashboardDataContext();

  if (dashDataError || (window.navigator.onLine && fetchStatus === 'paused')) {
    throw new AssetCareError(
      'No internet connection.',
      400,
      ErrorOrigin.DASHBOARDDATA
    );
  }

  return (
    <main>
      <DashboardHeader hospitalName="" signalRError={signalRError} />
      <StatusCardList devices={data} />
      {/* div here listed for filter-sidebar portal */}
      <div id={filterSidebarId} className="sticky top-64 z-1000" />
      <DashboardTable />
    </main>
  );
};
