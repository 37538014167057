import { AppRoute } from '@/@types';
import { AssetDetails, ChangeEmail, Dashboard } from '@/features';
import { NotFound, Root } from '@/ui';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: `${AppRoute.AssetDetails}/:id`,
        element: <AssetDetails />,
      },
      {
        path: AppRoute.ChangeEmail,
        element: <ChangeEmail />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
