import { QueryClient } from '@tanstack/react-query';

const DATA_REFRESH_INTERVAL = 20 * 60 * 1000; // 20 minutes

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime - time after which all queries are considered stale and are refetched on query key change
      staleTime: DATA_REFRESH_INTERVAL,
      // refetchInterval - time after which active queries are refetched and cache updated
      refetchInterval: DATA_REFRESH_INTERVAL,
      // Refetch even if the tab is in the background
      refetchIntervalInBackground: true,
      // gcTime - time after which inactive queries are garbage collected
      // and cache entries cleared (previously called cacheTime)
      gcTime: DATA_REFRESH_INTERVAL,
      retry: 3,
    },
  },
});
