import { AppRoute } from '@/@types';
import { Header } from '@/features/AssetDetails/components';
import { NovaNavigation } from '@novax/os';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Footer } from '../Footer/Footer';
import { Icon } from '../Icon/Icon';

export const ErrorPage: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();

  const assetDetailRegExp = new RegExp(AppRoute.AssetDetails);
  const isAssetDetailPage = assetDetailRegExp.test(window.location.pathname);

  const title =
    error.status >= 500
      ? t('errorPage.dataRetrieval')
      : t('errorPage.retrievingData');

  const message =
    error.status >= 500
      ? t('errorPage.unexpectedError')
      : t('errorPage.invalidRequest');

  return (
    <div className="flex min-h-screen grow flex-col">
      <NovaNavigation appTitle="Assets" className="top-0 !px-32" />
      <div className="mx-auto flex w-full max-w-1440 grow flex-col justify-between px-24 pt-80 md:px-32 md:pt-90">
        {isAssetDetailPage && (
          <Header lastUpdated={0} onGoBack={resetErrorBoundary} />
        )}
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 flex-col items-center justify-center gap-45 rounded-4 text-center text-base">
            <div>
              <h3 className="mb-12 font-segoe_ui text-16_24 font-semibold">
                {title}
              </h3>
              <p className="font-segoe_ui text-14_22">{message}</p>
            </div>

            <div className="flex items-center justify-center gap-16">
              <button
                className="flex items-center gap-10.9 rounded-4 border-1 border-blue-6 bg-blue-6 px-16 py-8 font-noto_sans text-14_19 font-semibold text-gray-11"
                onClick={resetErrorBoundary}
              >
                <Icon name="tryAgain" className="h-16 w-16" />
                {t('errorPage.tryAgain')}
              </button>
            </div>
          </div>
          <Icon
            name="olysenseLogoGray"
            className="w-235 mb-40 h-26 md:mb-120"
          />
        </div>
        <Footer />
      </div>
    </div>
  );
};
