import { TimeAgo } from '../interfaces';

export const resolveTimeAgo = (date?: string | Date): TimeAgo | undefined => {
  if (!date) return;

  const dateObject = typeof date === 'string' ? new Date(date) : date;
  const now = new Date();
  const diffTime = now.getTime() - dateObject.getTime();
  const diffMinutes = Math.round(diffTime / 60000);

  // 3 days
  if (diffMinutes > 4320) {
    return {
      translationKey: 'daysAgo',
      value: Math.round(diffMinutes / 1440),
    };
  }

  // 4 hours
  if (diffMinutes > 240) {
    return {
      translationKey: 'hoursAgo',
      value: Math.round(diffMinutes / 60),
    };
  }

  return {
    translationKey: 'minAgo',
    value: diffMinutes,
  };
};
