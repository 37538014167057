import { DeviceStatus } from '@/@types';
import { Card } from '@/ui';
import { useTranslation } from 'react-i18next';
import { ServiceStep } from '../enums';
import { ErrorSolution, TimeAgo, UserManualResponse } from '../interfaces';
import { ManualInstructionsCard } from './ManualInstructionsCard';
import { RepairStatusCard } from './RepairStatusCard';
import { StatusAlert } from './StatusAlert';

interface RepairErrorCardProps {
  status: DeviceStatus.ERROR_DETECTED | DeviceStatus.IN_REPAIR;
  errorMessage?: string;
  timeAgo?: TimeAgo;
  errorSolutions: ErrorSolution[];
  isLoanerRequested?: boolean;
  caseNumber?: number;
  repairCompletionDate?: Date;
  repairStep?: ServiceStep;
  userManual?: UserManualResponse;
}

export const RepairErrorCard: React.FC<RepairErrorCardProps> = ({
  status,
  errorMessage,
  timeAgo,
  errorSolutions,
  isLoanerRequested,
  caseNumber,
  repairCompletionDate,
  repairStep,
  userManual,
}) => {
  const { t } = useTranslation();

  return (
    <Card className="flex flex-col gap-y-16 md:gap-y-24">
      <h1 className="text-14_21 font-semibold text-text-base xl:text-16_24">
        {status === DeviceStatus.ERROR_DETECTED
          ? t('assetDetail.deviceSupport')
          : t('assetDetail.repairDetails')}
      </h1>
      <StatusAlert
        status={status}
        errorMessage={errorMessage}
        errorSolutions={errorSolutions}
        timeAgo={timeAgo}
        isLoanerRequested={isLoanerRequested}
      />
      {status === DeviceStatus.IN_REPAIR && (
        <RepairStatusCard
          caseNumber={caseNumber!}
          repairCompletionDate={repairCompletionDate!}
          repairStep={repairStep!}
        />
      )}
      {status === DeviceStatus.ERROR_DETECTED && (
        <ManualInstructionsCard
          userManual={userManual}
          errorSolutions={errorSolutions}
        />
      )}
    </Card>
  );
};
