import i18n from '@/i18n';
import { getConfig, NovaNavigation, useAuth } from '@novax/os';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { Footer } from '../Footer/Footer';
import { useTenantLanguage } from './hooks';

export const Root = () => {
  const { user } = useAuth();
  const config = getConfig();
  const tenantId = config?.AUTH_REALM ?? '';
  const { data: tenantLanguage } = useTenantLanguage(tenantId);

  // First look for user preferred language, then tenant language, then default to English
  useEffect(() => {
    let preferredLang = tenantLanguage?.defaultLocale ?? 'en';

    if (user?.preferredLang) {
      preferredLang = user?.preferredLang;
    }

    i18n.changeLanguage(preferredLang);
  }, [user?.preferredLang, tenantLanguage]);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <NovaNavigation appTitle="Assets" className="top-0 z-10 !px-32" />
      <div className="mx-auto flex min-h-screen w-full max-w-1440 flex-col justify-between px-24 pt-80 md:px-32 md:pt-90">
        <Outlet />
        <Footer />
      </div>
    </ErrorBoundary>
  );
};
